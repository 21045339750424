import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GlobalData } from "../../../assets/data/GlodalData";

import {
  FixedPrice,
  OntimeDelivery,
  TimeManage,
  HiringModel,
} from "../../../assets/data/Imagedata";


const SpecialServices = {
  service1: {
    title: "Amazing Logo",
    subtitle:
      "Under this model, the web development company and the client agree on a fixed price for the entire project scope.",
  },
  service2: {
    title: "Website Safety",
    subtitle:
      "The client pays based on the time and resources spent on the project.",
  },
  service3: {
    title: "Fully Mobile Responsive and Quick",
    subtitle:
      "The web development company commits to delivering projects on or before agreed-upon deadlines.",
  },
  service4: {
    title: "Intelligent Designer",
    subtitle:
      "if your project requires additional manpower and extra resources, were ready to scale up and bring in the necessary talent to meet your needs.",
  },
};



const LogofaqData = [
  {
    question: "What services do you offer?",
    answer:
      "We offer a wide range of services, including web development, mobile app development, custom software development, UX/UI design, and digital strategy consulting.",
  },
  {
    question: "What platforms do you develop apps for?",
    answer:
      "We develop apps for iOS, Android, and cross-platform solutions like React Native and Flutter.",
  },
  {
    question: "Do you offer both front-end and back-end development?",
    answer:
      "Yes, we provide both front-end and back-end development services to create fully functional web and mobile applications.",
  },
];




function App() {
  const [pagesData, setPagesData] = useState([]);
  const titleToFetch = 'Logo Design'; // Specify the title you want to fetch data for

  useEffect(() => {
    fetch('https://admin.cyberspacedigital.in/api/fetchPages.php')
      .then(response => response.json())
      .then(data => {
        const filteredData = data.filter(page => page.title === titleToFetch);
        setPagesData(filteredData);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [titleToFetch]);

  return (
    <main> 
    {pagesData.map(page => (
      <div key={page.id}>


      <section
  className="py-10 sm:py-40"
  style={{
    backgroundImage: `url('https://admin.cyberspacedigital.in/${page.feature_image}')`,
    alt: "Feature",
    backgroundSize: "cover",
    backgroundPosition: "center",
  }} >
     
          {/* Title Card */}
          <div className="flex flex-col items-center md:flex-row justify-center">
            <div className="md:w-1/2 md:pr-10"></div>
            <div className="md:w-1/2 px-5">
              <div className="text-right md:text-left">
                {/* Align titles to the right on mobile, left on larger screens */}
                <h3 className="cursor-default text-2xl pb-5 md:text-6xl fade-in-down font-extrabold text-center text-white tracking-tight">
                  {page.title}
                </h3>
                <p className="cursor-default text-white sm:text-sm text-xs text-center sm:ml-0">
                  {page.subtitle}
                </p>
                <div className="mt-8 flex flex-wrap justify-center gap-4">
                  <a href={`mailto:${GlobalData.company.companyEmail}`}>
                    <a className="transform rounded-md bg-white px-5 py-3 font-medium text-black transition-colors hover:bg-black hover:text-white">
                      Email
                    </a>
                  </a>
                  <a href={GlobalData.company.companyWhatsapp}>
                    <a className="transform rounded-md bg-white px-5 py-3 font-medium text-black transition-colors hover:bg-black hover:text-white">
                      WhatsApp
                    </a>
                  </a>
                </div>
              </div>
            </div>
          </div> 
        </section>


  {/* Subtitle section2 */}
  <section className="text-center mx-auto my-8">
        <h2 className="cursor-default pb-2 font-extrabold text-transparent text-2xl md:text-5xl lg:text-6xl bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600 mt-3  tracking-tight ">
          {page.title2}
        </h2>
        <p className="cursor-default text-sm font-medium text-black mt-5 mb-10">
          {page.subtitle2}
        </p>

        <div className="flex items-center justify-center gap-4 flex-wrap"></div>
      </section>





      {/* Why us for this */}
      <section className="bg-gray-200 ">
        <div className="grid max-w-screen-xl px-16 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto sm:mr-10 place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl ">
              {page.article_title}
            </h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl ">
              {page.article_content}
            </p>
            <a href="#"
              className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-black  "  >
              Get started
              <svg
                className="w-5 h-5 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </div>
          <div className=" lg:mt-0 lg:col-span-5 lg:flex rounded-lg">
            <img src={`https://admin.cyberspacedigital.in/${page.article_image}`} alt={page.title} 
              className="rounded-lg"
            />
          </div>
        </div>
      </section>


      {/* Process  */}
      <section id="services" className="pt-5 cursor-default section relative ">
        <div className="xl:max-w-8xl mx-auto px-4">
          <section className="text-center mx-auto mb-12 lg:px-20">
            <h2 className="pb-2 font-extrabold text-transparent text-2xl md:text-5xl lg:text-6xl bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600 ">
              Our Process
            </h2>
            <p className="text-sm font-medium text-black">
              "We also add additional features while creating websites."
            </p>
          </section>

          {/* "Fixed Price Model" section */}
          <div className="flex flex-wrap flex-row -mx-4 text-center">
            <div
              className="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/4 lg:px-6 wow fadeInUp"
              data-wow-duration="1s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationName: "fadeInUp",
              }}
            >
              <div className="py-8 px-12   transform transition duration-300 ease-in-out hover:-translate-y-2">
                <div className="inline-block text-gray-900 ">
                  <img
                    src={FixedPrice}
                    className="w-40 ml-auto"
                    alt="illustration"
                    loading="lazy"
                    width={900}
                    height={600}
                  />
                </div>
                <h3 className="text-lg leading-normal  font-semibold text-black">
                  {SpecialServices.service1.title}
                </h3>
                <p className="text-gray-500 text-sm">
                  {SpecialServices.service1.subtitle}
                </p>
              </div>
            </div>
            <div
              className="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/4 lg:px-6 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".1s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.1s",
                animationName: "fadeInUp",
              }}
            >
              <div className="py-8 px-12   transform transition duration-300 ease-in-out hover:-translate-y-2">
                <div className="inline-block text-gray-900">
                  <img
                    src={TimeManage}
                    className="w-40 ml-auto"
                    alt="illustration"
                    loading="lazy"
                    width={900}
                    height={600}
                  />
                </div>
                <h3 className="text-lg leading-normal font-semibold text-black">
                  {SpecialServices.service2.title}
                </h3>
                <p className="text-gray-500 text-sm">
                  {SpecialServices.service2.subtitle}
                </p>
              </div>
            </div>
            <div
              className="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/4 lg:px-6 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".3s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.3s",
                animationName: "fadeInUp",
              }}
            >
              <div className="py-8 px-12   transform transition duration-300 ease-in-out hover:-translate-y-2">
                <div className="inline-block text-gray-900 ">
                  <img
                    src={OntimeDelivery}
                    className="w-40 ml-auto"
                    alt="illustration"
                    loading="lazy"
                    width={900}
                    height={600}
                  />
                </div>
                <h3 className="text-lg leading-normal  font-semibold text-black">
                  {SpecialServices.service3.title}
                </h3>
                <p className="text-gray-500 text-sm">
                  {SpecialServices.service3.subtitle}
                </p>
              </div>
            </div>
            <div
              className="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/4 lg:px-6 wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay=".3s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.3s",
                animationName: "fadeInUp",
              }}
            >
              <div className="py-8 px-12   transform transition duration-300 ease-in-out hover:-translate-y-2">
                <div className="inline-block text-gray-900 ">
                  <img
                    src={HiringModel}
                    className="w-40 ml-auto"
                    alt="illustration"
                    loading="lazy"
                    width={900}
                    height={600}
                  />
                </div>
                <h3 className="text-lg leading-normal font-semibold text-black">
                  {SpecialServices.service4.title}
                </h3>
                <p className="text-gray-500 text-sm">
                  {SpecialServices.service4.subtitle}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* Logo Design Pricing */}
      <section>
        <div className="container max-w-full mx-auto py-24 px-6">
          <h1 className="text-center pb-2 font-extrabold text-transparent text-2xl md:text-5xl lg:text-6xl bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600 mt-3  tracking-tight">
            Pricing
          </h1>
          <p className="text-center text-lg text-gray-700 mt-2 px-6">
            We are here to get the best price for you.
          </p>
          <div className="h-1 mx-auto bg-indigo-200 w-24 opacity-75 mt-4 rounded" />
          <div className="max-w-full md:max-w-6xl mx-auto my-3 md:px-8">
            <div className="flex flex-wrap justify-center -mx-4">
               
                <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8" >
                  <div className="bg-white text-black rounded-lg  shadow-lg overflow-hidden">
                    <div className="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6">
                      <h1 className="text-lg font-medium uppercase p-3 pb-0 text-center tracking-wide">
                       BASIC
                      </h1>
                      <h2 className="text-sm text-gray-500 text-center pb-6">
                      {page.price_category1}
                      </h2>
                      {page.price_category1details}
                    </div>
                    <div className="flex flex-wrap mt-3 px-6">
                      <ul>
                       
                      </ul>
                    </div>
                    <div className=" flex items-center p-8 uppercase">
                      <button className="mt-3 text-lg font-semibold bg-black w-full text-white rounded-lg px-6 py-3 block shadow-xl hover:bg-gray-700">
                        Select
                      </button>
                    </div>
                  </div>
                </div>

                <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8" >
                  <div className="bg-white text-black rounded-lg  shadow-lg overflow-hidden">
                    <div className="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6">
                      <h1 className="text-lg font-medium uppercase p-3 pb-0 text-center tracking-wide">
                      PREMIUM
                      </h1>
                      <h2 className="text-sm text-gray-500 text-center pb-6">
                      {page.price_category2}
                      </h2>
                      {page.price_category2details}
                    </div>
                    <div className="flex flex-wrap mt-3 px-6">
                      <ul>
                       
                      </ul>
                    </div>
                    <div className=" flex items-center p-8 uppercase">
                      <button className="mt-3 text-lg font-semibold bg-black w-full text-white rounded-lg px-6 py-3 block shadow-xl hover:bg-gray-700">
                        Select
                      </button>
                    </div>
                  </div>
                </div>


                <div className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8" >
                  <div className="bg-white text-black rounded-lg  shadow-lg overflow-hidden">
                    <div className="block text-left text-sm sm:text-md max-w-sm mx-auto mt-2 text-black px-8 lg:px-6">
                      <h1 className="text-lg font-medium uppercase p-3 pb-0 text-center tracking-wide">
                      ECONOMIC
                      </h1>
                      <h2 className="text-sm text-gray-500 text-center pb-6">
                      {page.price_category3}
                      </h2>
                      {page.price_category3details}
                    </div>
                    <div className="flex flex-wrap mt-3 px-6">
                      <ul>
                       
                      </ul>
                    </div>
                    <div className=" flex items-center p-8 uppercase">
                      <button className="mt-3 text-lg font-semibold bg-black w-full text-white rounded-lg px-6 py-3 block shadow-xl hover:bg-gray-700">
                        Select
                      </button>
                    </div>
                  </div>
                </div>

            </div>
          </div>
        </div>
      </section>




  {/* FAQs */}
  <div className="py-10 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-semibold text-center text-gray-800 mb-5">
            Frequently Asked Questions
          </h2>
          <div className="grid gap-4">
            {LogofaqData.map((item, index) => (
              <div className="bg-white rounded-lg p-4 shadow-md" key={index}>
                <details className="group">
                  <summary className="flex justify-between items-center font-medium cursor-pointer">
                    <span>{item.question}</span>
                    <span className="transition group-open:rotate-180">
                      <svg
                        fill="none"
                        height={24}
                        shapeRendering="geometricPrecision"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        width={24}
                      >
                        <path d="M6 9l6 6 6-6" />
                      </svg>
                    </span>
                  </summary>
                  <p className="text-gray-600 mt-3 group-open:animate-fadeIn">
                    {item.answer}
                  </p>
                </details>
              </div>
            ))}
          </div>
        </div>
      </div>



   {/* Contact Us for Your Logo Needs */}
   <section className="text-center mx-auto my-8">
        <h2 className="cursor-default pb-2 font-extrabold text-transparent text-2xl md:text-5xl lg:text-6xl bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600 mt-3  tracking-tight ">
          Contact Us
        </h2>
        <p className="cursor-default text-sm font-medium text-black mt-5 mb-10">
          Contact Us for Your Logo Needs
        </p>

        <div className="flex items-center justify-center gap-4 flex-wrap">
          <a
            href={GlobalData.company.companyWhatsapp}
            className="text-xs sm:text-sm transform rounded bg-yellow-500 border border-slate-200 px-20 py-2 font-medium text-black transition-colors hover:bg-slate-50 "
          >
            Whatsapp
          </a>
          <Link to="/contactus">
            <button className="text-xs sm:text-sm transform rounded border border-slate-200 px-14 py-2 font-medium text-black transition-colors hover:bg-slate-50 hover:text-black">
              Contact us
            </button>
          </Link>
        </div>
      </section>


  {/* Request a Custom Design */}
  <section className="text-center mx-auto my-8">
        <h2 className="cursor-default pb-2 font-extrabold text-transparent text-2xl md:text-5xl lg:text-6xl bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-600 mt-3  tracking-tight ">
          Request a Custom {page.title}
        </h2>
        <p className="cursor-default text-sm font-medium text-black mt-5 mb-10">
          Explore our comprehensive documentation for more information on our
          services.
        </p>

        <div className="flex items-center justify-center gap-4 flex-wrap">
          <a
            href={GlobalData.company.companyWhatsapp}
            className="text-xs sm:text-sm transform rounded bg-yellow-500 border border-slate-200 px-20 py-2 font-medium text-black transition-colors hover:bg-slate-50 "
          >
            Whatsapp
          </a>
          <a
            href={`tel:${GlobalData.company.companyPhone}`}
            class="text-xs sm:text-sm transform rounded border border-slate-200 px-14 py-2 font-medium text-black transition-colors hover:bg-slate-50 hover:text-black"
          >
            Call Now
          </a>
        </div>
      </section>


        </div>
      ))}
     
    </main>
  );
}

export default App;
